'use client';

import { FC } from 'react';
import useTemplatePreview from 'components/UI/PreviewTemplate/hooks/useTemplatePreview';
import ZpImage from 'components/UI/Image/ZpImage';

const LinkPreviewBackground: FC = () => {
  const { backgroundImageBody, backgroundColorBody } = useTemplatePreview();

  return (
    <>
      {backgroundImageBody && (
        <ZpImage
          src={backgroundImageBody}
          width={450}
          height={950}
          alt='background'
          style={{
            filter: 'blur(15px)',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            backgroundImage: `url(${backgroundImageBody})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'fixed',
            // zIndex: -5,
          }}
        />
      )}

      {backgroundImageBody ? (
        <ZpImage
          src={backgroundImageBody}
          width={450}
          height={950}
          alt='background'
          style={{
            // backgroundColor: backgroundColorBody,
            height: '100vh',
            // backgroundImage: `url(${backgroundImageBody})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50%',
            backgroundSize: '100% 100vh',
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translate(-50%)',
            width: '100%',
            maxWidth: '448px',
            // zIndex: -1,
          }}
        />
      ) : (
        <div
          style={{
            backgroundColor: backgroundColorBody,
            height: '100vh',
            // backgroundImage: `url(${backgroundImageBody})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50%',
            backgroundSize: '100% 100vh',
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translate(-50%)',
            width: '100%',
            maxWidth: '448px',
            // zIndex: -1,
          }}
        />
      )}
    </>
  );
};

export default LinkPreviewBackground;
